<script>
  import Header from "../../components/Header.svelte";
  import { getServerState } from "../../utils";

  import { register, locale, init } from "svelte-i18n";
  import Footer from "../../components/Footer.svelte";

  register("en", () => import("../../../public/lang/en.json"));
  locale.set("en");
  localStorage.setItem("enrol-app-locale", "en");
  init({
    fallbackLocale: "en",
    initialLocale: "en",
  });

  let language;
  if (localStorage.getItem("enrol-app-locale")) {
    language = localStorage.getItem("enrol-app-locale");
  } else {
    language = "en";
  }

  let maintenance_message;
  let enabled;
  getServerState(language).then((res) => {
    if (res.enabled_maintenance_page) {
      maintenance_message = res.maintenance_page;
      enabled = res.enabled_maintenance_page;
    }
  });
</script>

<svelte:head>
  <title>Touchstone</title>
</svelte:head>
{#if enabled}
  {@html maintenance_message}
{:else}
  <Header />

  <div class="container">
    <div class="background">
      <br>
      <br>
      <h1>Touchstone Subscription</h1>
      <h2>
        Thank you for showing an interest in subscribing to Touchstone, OBOD’s
        official magazine, you have come to the right place!
        <br />
        <br />
        The way to subscribe to Touchstone has evolved and it is now available to
        access exclusively through the OBOD community -
        <b><i><a href="https://hearth.druidry.org" target="_blank" class="druid-link">The Druid Hearth</a></i></b>
        <br />
        <br />
        <b>The Druid Hearth</b> is the official OBOD online community where you’ll
        be able to find everything in one place. In the Hearth you can:
      </h2>      
      <br />
      <h2>
        <div class="center-section">
          <b>• Download membership resources</b><br />
          <b>• Access your online course</b><br />
          <b>• View upcoming events </b><br />
          <b>• Connect with other members </b><br />
          <b>• Access Touchstone including back issues </b><br />
          <b>• + Lots more!!</b>
        </div>
      </h2>      
      <br />
      <br />
      <a href="https://druidry.org/the-druid-hearth" target="_blank">
        <img
          src="/images/touchstone-subscription-graphic.png"
          alt="Touchstone Subscription Graphic"
          class="touchstone-subscription-graphic"
        />
      </a>
      <br />
      <br />
      <div class="dotted-divider"></div>
      <br />
      <br />
      <div class="image-container">
        <div class="left-column">
          <h2>
            <b>
              If you are a membe of the OBOD and have not yet<br />
              received an invitation to access the Druid <br />
              Hearth, you may need to update your contact <br />
              details. You can send us your updated contact<br />
              details using the following form:</b>
          </h2>
        </div>

        <div class="right-column">
          <a
            style="margin-top:30px"
            href="https://form.jotform.com/obodoffice/member_update"
            target="_blank"
            ><img
              src="/images/touchstone-form-button.png"
              alt="touchstone-form-button.png"
              class="touchstone-form-button"
            /></a>
        </div>
      </div>
    </div>
  </div>
  <Footer />
{/if}

<style>
  .container {
    width: 100%;
    max-width: 85rem;
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }

  .background {
    background-color: #fffce4;
    padding: 40px;
    margin-top: -30px;
    flex-grow: 1;
    box-sizing: border-box;
    height: 80vh;
    overflow-y: auto;
  }

  .center-section {
    text-align: center;
    line-height: normal;
  }

  .dotted-divider {
    border-bottom: 1px dotted black;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .druid-link {
    color: #3e7e7b;
    font-weight: 900 !important;
    font-style: italic !important;
    font-family: "Trebuchet MS", Gadget, sans-serif;
    text-decoration: underline;
  }

  .touchstone-subscription-graphic {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: block;
  }

  .image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("/images/touchstone-box-info.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 10px;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
  }

  .left-column {
    flex: 0 1 auto;
    font-size: 16px;
    color: black;
    margin-top: -30px;
    padding-right: 20px;
    margin-left: 100px;
    width: auto;
    max-width: 460px;
  }

  .right-column {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    text-align: center;
  }

  .touchstone-form-button {
    max-width: 100%;
    height: auto;
    max-height: 90%;
    object-fit: contain;
  }

  @media (max-width: 1186px) {
    .image-container {
      flex-direction: column;
      height: auto;
      padding: 20px;
      background-size: cover;
    }

    .left-column {
      font-size: 14px;
      padding-right: 0;
      margin-left: 0;
      text-align: center;
      width: 100%;
      margin-top: 20px;
    }

    .right-column {
      margin-top: 20px;
      width: 100%;
      justify-content: center;
      display: flex;
      text-align: center;
    }

    .touchstone-form-button {
      height: auto;
      min-width: 200px;
    }

    .background {
      padding: 20px;
    }
  }

  @media (max-width: 768px) {
    .background {
      overflow-y: auto;
    }
  }

  @media (max-width: 480px) {
    .background {
      overflow-y: auto;
    }
  }
</style>
