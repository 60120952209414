<div class="footer">
  <div class="footer-column footer-column-1" />

  <div class="footer-column left-column">
    <p>UK: office@druidry.org</p>
    <p>UK: +44 (0)1273 470888</p>
    <p>US: +1-857-293-6468</p>
  </div>

  <div class="footer-column right-column">
    <p>OBOD CIC</p>
    <p>Unit 1, The Granary,</p>
    <p>Highlands Farm,</p>
    <p>London Road</p>
    <p>Bolney, West Sussex</p>
    <p>RH17 5PX UK</p>
  </div>

  <div class="footer-column footer-column-4" />
</div>

<style>
  .footer {
    background-color: #113433;
    color: white;
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .footer-column {
    padding: 0 20px;
    text-align: left;
  }

  .left-column p,
  .right-column p {
    margin: 10px 0;
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    .footer {
      grid-template-columns: 1fr;
      padding: 10px;
    }

    .footer-column {
      margin-bottom: 10px;
    }

    .left-column,
    .right-column {
      text-align: center;
    }

    .left-column p,
    .right-column p {
      font-size: 13px;
      margin: 3px 0;
    }

    .footer-column-1 {
      display: none;
    }

    .footer-column-4 {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .footer {
      grid-template-columns: 1fr;
      padding: 10px;
    }

    .footer-column {
      margin-bottom: 10px;
    }

    .left-column,
    .right-column {
      text-align: center;
    }

    .left-column p,
    .right-column p {
      font-size: 13px;
      margin: 3px 0;
    }

    .footer-column-1 {
      display: none;
    }

    .footer-column-4 {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .footer {
      padding: 8px;
    }

    .left-column p,
    .right-column p {
      font-size: 12px;
      margin: 2px 0;
    }

    .footer-column-1 {
      display: none;
    }

    .footer-column-4 {
      display: none;
    }
  }
</style>
